<template>
    <section>
        <div class="login-background row mx-0 j-center a-center">
            <div class="box-login br-8 position-relative">
                <div class="w-100 h-100 position-absolute br-8 d-flex flex-column a-center pt-5" style="z-index:2;">
                    <div class="login-img obj-cover my-3" />
                    <p class="f-22 text-tema-login f-600 mt-4">
                        Bienvenido a Manager
                    </p>
                    <el-input v-model="usuario" placeholder="Usuario" class="w-60 my-4" />
                    <el-input type="password" v-model="contrasena" placeholder="Contraseña" show-password class="w-60" />
                    <div class="bg-general f-14 br-4 d-middle-center text-white w-60 my-3 cr-pointer" style="height:42px;" @click="login">
                        Ingresar
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
	layout: 'loginLayout',
	data(){
		return {
			usuario: '',
			contrasena: ''
		}
	},	
	methods:{
		async login(){
			try {
				
					// Submit the form.
					const payload = {
						'usuario' : this.usuario,
						'contrasena' : this.contrasena
					}
					const { data } = await axios.post('auth-manager/login',payload)
					// Save the token.
					this.$store.dispatch('auth/saveToken', {
						token: data.data.token,
						remember: false
					})

					// Fetch the user.
					await this.$store.dispatch('auth/fetchUser')

					// this.$notify({
					// 	title: 'Login Exitoso',
					// 	message: data.mensaje,
					// 	type: 'success'
					// });
					
					// Redirect home.
					this.$router.push({ name: 'home' })
			} catch (e){
				this.$notify({
					title: 'Login Fallido',
					message: "Verifique los datos de autenticación",
					type: 'warning'
				});
			}
    }
	}
}
</script>
<style lang="scss" scoped>
.login-background{
	background: rgba(90,128,234, 10%);
	height: 100vh;
	.box-login{
		background-color: rgba(255,255,255, 90%);
		box-shadow: 0 3px 6px var(--shadow);
		height: 528px;
		width: 513px;
		.login-img{
			height: 146px;
			width: 146px;
			background-image: url('/img/logos/logoOrbitaOscuro.png');
			background-size: cover;
		}
	}
}
.w-60{
    width: 60%;
}
.text-tema-login{
    color: var(--color-general);
}
</style>